.event-form h3 {
  text-align: center;
  padding-bottom: 10px;
}

.event-form {
  padding-bottom: 50px;
}

#event-form-buttons{
  justify-content: center;
}
