.event-header {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.event-header img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.event-details {
  width: 600px;
  padding-left: 50px;
}

.event-details p {
  font-size: 20px;
}

.event-details .label {
  font-weight: bold;
}

.dish-list {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 50px;
}

.dish-list h3 {
  padding-bottom: 10px;
  text-align: center;
}

.dish-list .alert{
  margin-left: 0;
  margin-right: 0
}

.add-dish {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 10px;
}
