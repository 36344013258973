.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#footer {
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 2.5rem; 
  padding-top: 50px;
  padding-bottom: 50px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

h1 {
  text-align: center;
  padding-bottom: 20px;
}

.navbar {
  margin-bottom: 20px;
  background-color: mediumaquamarine;
}

.nav-item {
  display: flex;
  align-items: center;
}

#nav-crockpot{
  width: 70px;
  height: 70px;
}

#logo {
  font-size: 25px;
  padding-right: 25px;
}

#link {
  font-size: 20px;
  padding-right: 50px;
}

#nav-search{
  width: 300px;
}

.alert {
  margin-left: 100px;
  margin-right: 100px;
}

#btn-mint {
  color: black;
  background-color: mediumaquamarine;
  border-color: mediumaquamarine;
}

#btn-mint:hover {
  background-color: aquamarine;
}

#btn-mint-left {
  margin-right: 20px;
  color: black;
  background-color: mediumaquamarine;
  border-color: mediumaquamarine;
}

#btn-mint-left:hover {
  background-color: aquamarine;
}

#btn-new-recipe {
  color: black;
  background-color: mediumaquamarine;
  border-color: mediumaquamarine;
  margin-bottom: 100px;
}

#btn-new-recipe:hover {
  background-color: aquamarine;
}

#btn-gray {
  color: white;
  background-color: #837F7E;
  border-color: #837F7E;
}

#btn-gray:hover {
  background-color: rgb(186, 183, 183);
}

#btn-gray-right {
  margin-left: 20px;
  color: white;
  background-color: #837F7E;
  border-color: #837F7E;
}

#btn-gray-right:hover {
  background-color: rgb(186, 183, 183);
}

#light-gray {
  background-color: #EDF0ED;
}

.recipe {
  padding-bottom: 100px;
}

.form-row {
  justify-content: left;
  padding-left: 150px;
  padding-right: 150px;
}

#selected-recipe-section {
  display: flex;
  align-items: center;
}

#selected-label {
  min-width: 200px;
  margin-right: 10px;
}

#clear-button {
  color: white;
  background-color: #837F7E;
  border-color: #837F7E;
}

#clear-button:hover {
  background-color: rgb(186, 183, 183);
}
