.recipe-header {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.recipe-header img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.recipe-details {
  width: 600px;
  padding-left: 50px;
}

.recipe-details p {
  font-size: 20px;
}

.recipe-details .label {
  font-weight: bold;
}

.recipe-ingredients {
  font-size: 20px;
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 50px;
  white-space: pre-line;
}

.recipe-directions {
  font-size: 20px;
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 50px;
  white-space: pre-line;
}

.missing-recipe {
  padding-top: 50px;
  text-align: center;
}

#select-recipe {
  vertical-align: text-top;
  margin-top: 5px;
  margin-left: 20px;
  color: black;
  background-color: mediumaquamarine;
  border-color: mediumaquamarine;
}

#select-recipe:hover {
  background-color: aquamarine;
}
