.table img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.table {
  font-size: 18px;
  table-layout: fixed;
  width: 100%
}

.table thead th {
  width: 5%;
}

.table thead th:nth-child(2) {
  width: 15%;
}

.table thead th:nth-child(3) {
  width: 15%;
}

.table thead th:nth-child(4) {
  width: 10%;
}

.table thead th:nth-child(5) {
  width: 20%;
}
