.dish-form {
  margin-bottom: 100px;
}

.dish-form span {
  margin-right: 10px;
}

.dish-form input {
  margin-right: 10px;
}

.dish-form .alert {
  margin-left: 0;
  margin-right: 0;
}

.dish-form h3 {
  padding-bottom: 10px;
  text-align: center;
}

.dish-form .form-row {
  padding-left: 0;
  padding-right: 0
}

#dish-form-buttons{
  justify-content: center;
}

#dish-photo-upload {
  color: black;
    background-color: mediumaquamarine;
    border-color: mediumaquamarine;
}

#dish-photo-upload:hover {
  background-color: aquamarine;
}
