.sign-up-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-up-form .form-row {
  width: 100%;
  justify-content: center;
  padding: 10px;
}
