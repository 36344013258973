.form-group .btn-gray-right {
    margin-left: 20px;
    color: white;
    background-color: #837F7E;
    border-color: #837F7E;
}

.form-group .btn-gray-right:hover {
  background-color: rgb(186, 183, 183);
}

#recipe-photo-upload {
  color: black;
    background-color: mediumaquamarine;
    border-color: mediumaquamarine;
}

#recipe-photo-upload:hover {
  background-color: aquamarine;
}

#filename{
  padding-left: 10px;
}

#recipe-form-buttons{
  justify-content: center;
}

#recipe-section h3 {
  padding-bottom: 10px;
}
