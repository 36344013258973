.recipes {
  padding-left: 100px;
  padding-bottom: 50px;
}

.recipe-list {
  display: flex;
  flex-wrap: wrap;
}

.recipe-list .card {
  margin: 20px;
  align-items: center;
  text-align: center;
  width: 250px;
}

.recipe-list .card-img-top {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-top: 30px;
}

#card-link {
  color: #22282F;
}
