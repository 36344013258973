#select-external-recipe {
  vertical-align: text-top;
  margin-top: 5px;
  color: black;
  background-color: mediumaquamarine;
  border-color: mediumaquamarine;
}

#select-external-recipe:hover {
  background-color: aquamarine;
}
