.search h1 {
  margin-bottom: 25px;
}

.search h3 {
  text-align: center;
}

#search-form{
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

#recipe-search{
  width: 400px;
}

#external-results {
  padding-bottom: 100px;
}
