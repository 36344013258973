.home h1 {
  padding-bottom: 50px;
}

.carousel {
  max-width: 700px;
  max-height: 500px;
  margin: auto;
}

.carousel h3 {
  font-weight: bold;
  text-shadow: 2px 2px black;
}

.carousel a {
  text-decoration: none;
  color: white;
}

.carousel a:hover {
  text-decoration: none;
  color: rgb(186, 183, 183);
}

#home-crockpot{
  width: 70px;
  height: 70px;
}
